import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import clevertap from "clevertap-web-sdk";

export default function FacebookLanding() {
  const navigate = useNavigate();

  useEffect(() => {
    const ua = navigator.userAgent;
    if (ua.indexOf("FBAN") > -1 || ua.indexOf("FBAV") > -1) {
      // facebook browser detected
      clevertap.event.push("From Facebook Browser");
      const isAndroidDevice = /Android/i.test(ua);
      const isIOSDevice = /iPhone|iPad|iPod/i.test(ua);

      if (isAndroidDevice) {
        const currentUrl = window.location.href;
        const intentUrl = `intent://${currentUrl.replace(
          /^https?:\/\//,
          ""
        )}#Intent;scheme=https;package=com.android.chrome;end;`;
        window.location.href = intentUrl;
      } else if (isIOSDevice) {
        const currentUrl = window.location.href;
        const safariUrl = `x-safari-https://${currentUrl.replace(
          /^https?:\/\//,
          ""
        ) }`;
        window.location.href = safariUrl;
      }
    } else {
      navigate("/login");
    }
  }, [navigate]);

  return (
    <div className="text-white">
      <div className="flex items-center justify-center mt-8">
        <img src="/images/p6_logo.svg" alt="logo" className="w-1/2" />
      </div>
      <div className="text-xl px-8 text-center">
        Looks like you&apos;re coming from Facebook.
      </div>
      <div className="m-4 text-sm text-center bg-dark-grey p-2.5 rounded-lg">
        The Facebook browser you&apos;re coming from does not support the
        features we need to run this app.
      </div>
      <div className="text-[30px] font-extrabold text-center animate-pulse">
        Don&apos;t Worry!
      </div>
      <div className="flex flex-col items-center my-6 px-8">
        <div className="flex items-center justify-center text-[20px] gap-2 border-b w-full pb-2">
          <div className="text-[25px] bg-p6blue rounded-full w-8 h-8 flex items-center justify-center">
            1
          </div>
          Step 1
        </div>
        <div className="my-3">Click this icon under the blue arrow.</div>
        <img
          src="/icons/fm_upload_icon.svg"
          className="w-6"
          alt="Messenger Options"
        />
      </div>
      <div className="flex flex-col items-center px-8">
        <div className="flex items-center justify-center text-[20px] gap-2 border-b w-full pb-2">
          <div className="text-[25px] bg-p6blue rounded-full w-8 h-8 flex items-center justify-center">
            2
          </div>
          Step 2
        </div>
        <div className="my-3">Click on the option for your browser.</div>
        <div className="w-full bg-white text-black p-2 rounded-md pl-3">
          Open in Safari
        </div>
        <div className="m-2">Or</div>
        <div className="w-full bg-white text-black p-2 rounded-md pl-2">
          Open in Chrome
        </div>
      </div>
      <div className="absolute bottom-0 right-0 ...">
        <img
          src="/icons/arrow_down_icon.svg"
          alt="arrow"
          className="w-[50px] mr-2 animate-bounce"
        />
      </div>
    </div>
  );
}
