import { Fragment, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import copy from "copy-to-clipboard";
import P6Button from "../../../../components/button";
import { FieldValues, useForm } from "react-hook-form";
import type { SubmitHandler } from "react-hook-form";
import { updateGroup } from "../../../../functions";
import { P6ClientError } from "../../../../utils/exeptions";
import { P6ApiResponse } from "../../../../../../src/types";

export default function AddMembers({
  groupId,
  groupCode,
}: {
  groupId: string;
  groupCode: string;
}) {
  const [open, setOpen] = useState(false);
  const [openCode, setOpenCode] = useState<boolean>(false);
  const [codeError, setCodeError] = useState<string>("");
  const cancelButtonRef = useRef(null);
  const [copied, setCopied] = useState<boolean>(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FieldValues>();
  let baseURL = "";

  if (!import.meta.env.MODE || import.meta.env.MODE === "development") {
    baseURL = "http://localhost:3000";
  } else {
    baseURL = "https://app.platformsixx.com";
  }

  function copyLink() {
    const encodedGroupCode = encodeURIComponent(groupCode || groupId);
    const url = `${baseURL}/join/${encodedGroupCode}`;
    copy(url);
    setTimeout(() => {
      setCopied(false);
    }, 3000);
  }

  const onSubmit: SubmitHandler<FieldValues> = async (data) => {
    try {
      const result = await updateGroup({
        groupId: groupId,
        groupCode: data.groupCode as string,
      });
      const resultData = result.data as P6ApiResponse;
      if (resultData.success) {
        setOpenCode(false);
      } else {
        if (resultData.message) {
          setCodeError(resultData.message);
        } else {
          setCodeError("Code is not accepted");
        } 
      }
    } catch (error) {
      const result = new P6ClientError("UnknownError", error as string);
      return result;
    }
  };

  return (
    <div>
      <div
        className="cursor-pointer"
        onClick={() => {
          setOpen(true);
        }}
      >
        <div className="flex items-center px-2.5 mt-2.5 cursor-pointer">
          <div className="mr-2">
            <img
              src="/icons/add_icon.svg"
              alt="Group Events"
              className="m-auto h-auto w-auto"
              width={30}
              height={30}
            />
          </div>
          <div className="text-white">Add Members</div>
        </div>
      </div>
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-50"
          initialFocus={cancelButtonRef}
          onClose={setOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-2.5 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative max-w-screen-xs w-full transform overflow-hidden rounded-lg bg-component text-left shadow-xl transition-all sm:my-2.5">
                  <div className="bg-component px-2.5 sm:pb-4">
                    <div className="flex justify-end pt-2.5">
                      <img
                        src="/icons/X_icon.svg"
                        alt="Close"
                        className="h-auto w-auto cursor-pointer"
                        width={17.93}
                        height={17.93}
                        onClick={() => setOpen(false)}
                      />
                    </div>
                    <div className="relative flex flex-col items-center">
                      <div className="mt-2.5 flex">
                        <div className="flex flex-grow items-start justify-center text-[18px] text-white">
                          <img
                            src="/icons/refer_icon.svg"
                            alt="Refer"
                            className=""
                            width={80}
                            height={81.52}
                            onClick={() => setOpen(false)}
                          />
                        </div>
                      </div>
                      <div className="my-2.5 text-center text-white">
                        <div>Add members:</div>
                      </div>
                      <div className="text-white py-2.5 text-center">
                        <div className="text-lg">Group Code:</div>
                        {openCode ? (
                          <form
                            onSubmit={handleSubmit(onSubmit)}
                            className="mt-2.5"
                          >
                            <div className="">
                              <div className="relative">
                                <input
                                  data-cy="group-code"
                                  type="text"
                                  id="groupCode"
                                  autoComplete="off"
                                  className="text-field peer"
                                  placeholder=" "
                                  {...register("groupCode", {
                                    required: true,
                                  })}
                                />
                                <label
                                  htmlFor="groupCode"
                                  className="form-label"
                                >
                                  Group Code
                                </label>
                              </div>
                              {errors.groupCode && (
                                <p className="form-error pt-3"> is Required</p>
                              )}
                            </div>
                            <div className="flex space-x-2 h-5 mt-2 w-full">
                              <P6Button
                                sm
                                div
                                onClick={() => setOpenCode(!openCode)}
                                text="Close"
                                color="bg-dark-grey"
                              />
                              <P6Button
                                sm
                                type="submit"
                                text="Set"
                                color="bg-green"
                              />
                            </div>
                            {codeError && <div>{codeError}</div>}
                          </form>
                        ) : (
                          <div className="text-sm cursor-pointer">
                            <div
                              className="text-xs text-p6blue cursor-pointer"
                              onClick={() => setOpenCode(!openCode)}
                            >
                              customize
                            </div>
                            {groupCode}
                          </div>
                        )}
                      </div>
                      <img
                        src={`https://api.qrserver.com/v1/create-qr-code/?size=150x150&data=${baseURL}/join/${groupCode || groupId}`}
                        alt="qrcode"
                      />
                    </div>
                  </div>
                  <div className="flex p-6 text-white">
                    <P6Button
                      sm
                      color="bg-green"
                      onClick={() => copyLink()}
                      text={copied ? "Link Copied" : "Copy Link"}
                    />
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  );
}
